<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12 class="align-self-start">
        <v-layout wrap justify-center>
          <v-flex>
            <Login v-if="winStep == 0" @stepper="winStepper" />
            <!-- <LoginSelect
              v-if="winStep == 1"
              v-bind:storage="pageData"
              @stepper="winStepper"
            />
            <LoginVerify
              v-if="winStep == 2"
              v-bind:storage="pageData"
              @stepper="winStepper"
            /> -->
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Login from "./../Authentication/adminLogin";
// import LoginSelect from "./../Authentication/loginSelect";
// import LoginVerify from "./../Authentication/loginVerify";
export default {
  components: {
    Login,
    // LoginSelect,
    // LoginVerify,
  },
  data() {
    return {
      winStep: 0,
      phone: null,
      pageData: {},
    };
  },
  methods: {
    winStepper(window_data) {
      this.winStep = window_data.winStep;
      if (window_data.response) this.pageData = window_data.response;
      if (window_data.auth) this.pageData["auth"] = window_data.auth;
    },
  },
};
</script>
<style>
.login_page {
  background-color: #2aa86638;
  background-image: url("./../../assets/images/bg.jpg");
  background-attachment: fixed;
  background-size: cover;
}
</style>