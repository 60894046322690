<template>
  <div>
    
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389  "
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-app id="inspire">
      <v-layout wrap justify-center class="login_page">
        <v-flex xs12 sm6 md4 lg3 px-2 align-self-center>
          <v-layout wrap justify-center>
            <v-flex xs112 xl10>
              <v-snackbar
                v-model="showSnackBar"
                color="#68D389"
                right
                :timeout="timeout"
              >
                <v-layout wrap justify-center>
                  <v-flex text-left class="align-self-center">
                    <span style="color: #000">
                      {{ msg }}
                    </span>
                  </v-flex>
                  <v-flex text-right>
                    <v-btn
                      small
                      :ripple="false"
                      text
                      @click="showSnackBar = false"
                    >
                      <v-icon style="color: #000">mdi-close</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-snackbar>
              <v-card tile flat>
                <v-layout wrap justify-center>
                  <v-flex px-8 py-6>
                    <v-form ref="form" @submit.prevent="validateInput">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <span
                            style="
                              font-family: poppinsmedium;
                              font-size: 15px;
                              color: #b8b8b8;
                            "
                          >
                            Welcome to
                          </span>
                        </v-flex>
                        <v-flex xs12 py-4 style="cursor: pointer">
                          <router-link to="/">
                            <span
                              style="
                                font-family: poppinsbold;
                                font-size: 20px;
                                cursor: pointer;
                                color: #545454;
                              "
                            >
                            Wayanad Wildlife Sanctuary
                            </span>
                          </router-link>
                        </v-flex>
                        <v-flex xs12>
                          <v-layout wrap justify-center>
                            <v-flex xs6 lg4 px-2>
                              <span
                                style="
                                  font-family: poppinssemibold;
                                  font-size: 15px;
                                  color: #000;
                                "
                              >
                                Enter OTP
                              </span>
                              <v-progress-linear
                                height="2"
                                value="100"
                                color="#68D389"
                              ></v-progress-linear>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex pt-8 xs12 align-self-center>
                          <v-layout wrap justify-center>
                            <v-flex xs12 pb-2>
                              <span
                                style="
                                  font-family: ;
                                  font-size: 12px;
                                  color: #ababab;
                                "
                              >
                                Enter the 4 digit OTP sent on
                                {{ pageData.phoneno }}
                              </span>
                            </v-flex>
                            <v-flex xs9 sm7 md9 lg8 align-self-center>
                              <v-form @submit.prevent="validateInput">
                                <v-otp-input
                                  input-classes="otp-input"
                                  :length="4"
                                  v-model="otp"
                                  separator
                                  :shouldAutoFocus="true"
                                  @on-complete="validateInput"
                                />
                              </v-form>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 py-6>
                          <v-btn
                            block
                            tile
                            color="#68D389"
                            light
                            :ripple="false"
                            depressed
                            @click="validateInput"
                            class="itemValue"
                          >
                            Continue
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-form>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-app>
  </div>
  
</template>
<script>
// import VieOtpInput from "@bachdgvn/vue-otp-input";
import axios from "axios";
import store from "./../../store";
export default {
  props: ["pageData"],
  data() {
    return {
      phoneno: this.pageData.phoneno,
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      msg2: null,
      otp: "",
      timeLeft: "00:00",
      intervalTimer: null,
    
      otpexpired: false,
      loading: false,
      formValid: false,
    };
  },
  //   computed: {
  //     prefix() {
  //       return ''
  //     }
  //   },
  beforeMount() {
    this.setTime(60);
  },
  methods: {
    // validateOTP(value) {
    //   this.otp = value;
    //   this.validateInput();
    // },
    validateInput() {
      this.login();
    },
    login() {
      // this.$router.push("/")
      this.appLoading = true;
      var userData = {};
      userData["phone"] = this.pageData.phoneno;
      userData["otp"] = this.otp;
      // userData["role"] = "admin";
      // userData["accessType"] = "signin";
      axios({
        method: "POST",
        url: "/user/signup/signinadmin",
        // url: "/user/login",
        data: userData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            store.commit("userType", response.data.role);
            store.commit("userData", response.data.userdata);
            store.commit("menu", response.data.menu);
            store.commit("loginUser", response.data.token);


            // store.commit("loginUser", true);
            // this.$store.commit("userType", response.data.role);
            // store.commit("userData", response.data.userdata);
            // console.log(response.data.data);
            // localStorage.setItem("token", response.data.data.token);
            // if (response.data.data) {
            //   //   store.commit("userType", true);
            //   // this.$router.push("/login3");
            // } else {
            //   this.msg2 = "Unauthorised access";
            // }
          } else {
            // this.msg2 = response.data.msg;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    sentOTP() {
      var userData = {};
      userData["phone"] = this.pageData.phoneno;
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/getsms",
        data: userData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.setTime(60);
            this.otpexpired = false;
            this.msg = "OTP Sent Successfully";
            this.showSnackBar = true;
          } else {
            this.msg = "Something Went Wrong";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    setTime(seconds) {
      clearInterval(this.intervalTimer);
      this.timer(seconds);
    },
    timer(seconds) {
      const now = Date.now();
      const end = now + seconds * 1000;
      this.displayTimeLeft(seconds);
      this.countdown(end);
    },
    countdown(end) {
      this.intervalTimer = setInterval(() => {
        const secondsLeft = Math.round((end - Date.now()) / 1000);
        if (secondsLeft == 0) this.otpexpired = true;
        if (secondsLeft < 0) {
          clearInterval(this.intervalTimer);
          return;
        }
        this.displayTimeLeft(secondsLeft);
      }, 1000);
    },
    displayTimeLeft(secondsLeft) {
      const minutes = Math.floor((secondsLeft % 3600) / 60);
      const seconds = secondsLeft % 60;
      var seconds1 = seconds;
      if (seconds < 10) seconds1 = "0" + seconds;
      this.timeLeft = "0" + minutes + " : " + seconds1;
    },
  },
};
</script>
<style lang="sass" scoped>
.__card
  max-width: 600px
  margin: 0 auto

.layout-auth

  height: 50%
  width: 100%
  position: absolute
  top: 0
  left: 0
  content: ""
  z-index: 0

.layout-authi

  height: 100%
  width: 100%
</style>
<style >
.layout-auth-background {
  background-color: #171515;
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
.layout-authi {
  height: 100%;
  width: 100%;
}

.otp-input {
  width: 35px !important;
  height: 35px !important;
  color: black;
  margin-left: 5px;
  font-size: 20px !important;
  border-bottom: 1px solid #d6d6d6;
  outline: 0;
  border-width: 1px 1px 1px;
  text-align: center;
  padding-left: 0;
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #c8c8c8;
}
.separator::before {
  margin-right: 0.25em;
}
.separator::after {
  margin-left: 0.25em;
}
@media screen and (max-width: 768px) {
  .otp-input {
    width: 25px !important;
    height: 25px !important;
    padding: 4px !important;
    margin: 0 10px;
    font-size: 15px !important;
    border: 1px solid #d6d6d6;
    outline: 0;
    border-width: 1px 1px 1px;
    text-align: "center";
    padding-left: 12px;
  }
}
</style>